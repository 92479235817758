<template>
  <v-app>
    <v-container>
      <v-simple-table>
        <template v-slot:top>
          <v-row>
            <v-card-title>List of reports</v-card-title>
            <v-spacer></v-spacer>
            <v-chip class="mt-10">{{ currentCenter.name }}</v-chip>
          </v-row>
        </template>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">No</th>
              <th class="text-left">Reports name</th>
              <th class="text-left">Center level</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(report, i) in reports" :key="i">
              <td>{{ i + 1 }}</td>
              <td>
                <router-link :to="{ name: 'specificReports', params: { reportId: report.id, reportName: report.name }}">{{ report.name }}</router-link>
                <!-- <span
                  @click="
                    changeRoutes({ to: report.to, params: report.params })
                  "
                >
                  {{ report.name }}
                </span> -->
              </td>
              <td>TC</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>
  </v-app>
</template>
<script>
import { mapState } from 'vuex'
// import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
export default {
  data () {
    return {
      reports: []
      // reports: [
      //   {
      //     index: 1,
      //     name: 'List of delinquents',
      //     centerLevel: 'TC',
      //     to: '/reports/list',
      //     params: {
      //       reportIdentifier: 'allDelinquents'
      //     }
      //   },
      //   {
      //     index: 2,
      //     name: 'List of delinquents by general screening decision',
      //     centerLevel: 'RC',
      //     to: '/reports/list',
      //     params: {
      //       reportIdentifier: 'allDelinquents'
      //     }
      //   },
      //   {
      //     index: 3,
      //     name: 'List of delinquents by medical screening decision',
      //     centerLevel: 'TC',
      //     to: '/reports/list',
      //     params: {
      //       reportIdentifier: 'allDelinquents'
      //     }
      //   },
      //   {
      //     index: 4,
      //     name: 'List of escaped delinquents',
      //     centerLevel: 'TC',
      //     to: '/reports/list',
      //     params: {
      //       reportIdentifier: 'allDelinquents'
      //     }
      //   },
      //   {
      //     index: 5,
      //     name: 'List of reunified delinquents',
      //     centerLevel: 'TC',
      //     to: '/reports/list',
      //     params: {
      //       reportIdentifier: 'allDelinquents'
      //     }
      //   },
      //   {
      //     index: 6,
      //     name: 'List of delinquents by trade',
      //     centerLevel: 'TC',
      //     to: '/reports/list',
      //     params: {
      //       reportIdentifier: 'allDelinquents'
      //     }
      //   }
      // ]
    }
  },
  computed: {
    ...mapState('store', [
      'snackbar',
      'color',
      'text',
      'timeout',
      'currentUser',
      'currentCenter'
    ])
  },
  mounted () {
    this.getReports()
  },
  methods: {
    changeRoutes (route) {
      const userId = '123'
      this.$router.push({ name: 'Delinquent', params: userId })
    },
    getReports () {
      this.$reportService.getReportList().then((res) => {
        this.reports = res
      })
    }
  }
}
</script>
